import React from 'react';
import {useSpring, animated} from 'react-spring';

export default function Welcome() {
    const fade = useSpring({opacity: 1, from: {opacity: 0}, delay: 750})
    const moveup = useSpring({opacity: 1, from: {opacity: 0}, delay: 1000})
    return (
        <div>
            <animated.div style={fade}>
                <div className="container py-1">
                    <div className="row">
                        <div className="col-lg-8 offset-lg-2">
                            <br/><br/><br/>
                            <div id="welcome" className="text-center center">
                                <h2 className="display-4 mb-3" style={h2Style}>Civil Labs</h2>
                                <h4 className="display-4 mb-3" style={h4Style}>Developing web, mobile and desktop applications in the area of civil engineering ...</h4>
                                <div className="col-md-8 offset-md-2"><br />
                                    <h5>Services</h5>
                                    <p style={pStyle}>Web based systems, analysis and design softwares and any general purpose websites </p>
                                    <h5>Contact</h5>
                                    <p style={pStyle}>Tel: +251911280520 <br />Email: <a href="mailto: civilabsmail@gmail.com">civilabsmail@gmail.com</a></p>
                                </div>
                            </div>
                            <br/><br/>
                        </div>
                    </div>
                </div>
            </animated.div>
            <animated.div style={moveup}>
                <div className="container py-1">
                    <div className="row">
                        <div className="col-lg-8 offset-lg-2">
                        <div id="welcome" className="text-center center">
                                <h2 className="display-4 mb-3" style={h2Style}>Products</h2>
                                <a href="https://test.civilab.net" target="_blank" rel="noopener noreferrer" style={aStyle}>
                                    <div className="card  p-3 mt-4">
                                        <div className="container">
                                            <h2 className="display-4" style={h2Style2}>Civil Labs - Test</h2>
                                            <small>A geotechnical and material test management platform</small>
                                        </div>
                                    </div>
                                </a>
                                <a href="https://ml.civilab.net" target="_blank" rel="noopener noreferrer" style={aStyle}>
                                    <div className="card  p-3 mt-4">
                                        <div className="container">
                                            <h2 className="display-4" style={h2Style2}>Civil Labs - Machine Learning</h2>
                                            <small>A Collection of machine learning models in the area of civil engineering</small>
                                        </div>
                                    </div>
                                </a>
                                <a href="https://eacecivil.org" target="_blank" rel="noopener noreferrer" style={aStyle}>
                                    <div className="card  p-3 mt-4">
                                        <div className="container">
                                            <h2 className="display-4" style={h2Style2}>EACE Official Website</h2>
                                            <small>Official website and member registration system for the Ethiopian Association of Civil Engineers (EACE)</small>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </animated.div>
        </div>
        
    )
}

const h2Style = {
    fontSize: '40px'
}

const h2Style2 = {
    fontSize: '23px'
}

const h4Style = {
    fontSize: '1.1rem'
}

const aStyle = {
    textDecoration: 'none',
    color: '#000'
}

const pStyle = {
    color: '#444'
}
