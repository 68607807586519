import React from 'react';
import {useSpring, animated} from 'react-spring';

export default function Labs() {
    const props = useSpring({opacity: 1, from: {opacity: 0}})
    return (
        <animated.div style={props}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 offset-md-2">
                        <div style={paddingStyle}></div>
                        <div id="welcome">
                            <h2 className="display-4 mb-3" style={h2Style}>Labs</h2>
                            <ul>
                                <li><a href="https://test.civilab.net">Geotechnical laboratory information management system</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </animated.div>
        
    )
}

const h2Style = {
    fontSize: '1.4rem'
}

const paddingStyle = {
    padding: '50px'
}
