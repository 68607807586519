import React from 'react';
import {useSpring, animated} from 'react-spring';

export default function About() {
    const props = useSpring({opacity: 1, from: {opacity: 0}})
    return (
        <animated.div style={props}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 offset-md-2">
                        <div style={paddingStyle}></div>
                        <div id="welcome" className="text-center center">
                            <h1 className="display-4 mb-3" style={h2Style}>About</h1>
                            <p>Civil Labs provides software solutions primarily targeted to the area of Civil Engineering, including:</p>
                            <ul>
                                <li><p>Web based data analysis and management systems</p></li>
                                <li><p>Analysis and design softwares</p></li>
                                <li><p>Any general purpose websites</p></li>
                            </ul>
                            <h2 className="display-4 mb-3" style={h2Style}>Contact us any time...</h2>
                            <ul>
                                <li><p>Tel: 0911280520</p></li>
                                <li><p>Email: civilabsmail@gmail.com</p></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </animated.div>
        
    )
}

const h2Style = {
    fontSize: '1.4rem'
}

const paddingStyle = {
    padding: '100px'
}
