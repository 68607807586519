import React from 'react';
import { Link } from 'react-router-dom';

export default function Navbar() {
    return (
        <div>
            <nav className="navbar fixed-top navbar-expand-lg navbar-dark bg-dark fixed-top">
                <div className="container">
                    <Link className="navbar-brand" to="/">Civil Labs</Link>
                    <button className="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse"
                        data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false"
                        aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    {/* <div className="collapse navbar-collapse" id="navbarResponsive">
                        <ul className="navbar-nav ml-auto" style={navStyle}>
                            <li className="nav-item" style={navStyle}>
                                <Link className="nav-link" to="/labs">Labs</Link>
                            </li>
                            <li className="nav-item" style={navStyle}>
                                <Link className="nav-link" to="/about">About</Link>
                            </li>
                            <li className="nav-item" style={navStyle}>
                                <Link className="nav-link" to="/login">Login</Link>
                            </li>
                            <li className="nav-item" style={navStyle}>
                                <Link className="nav-link" to="/register">Register</Link>
                            </li>
                        </ul>
                    </div> */}
                </div>
            </nav>
        </div>
    )
}

// const navStyle = {
//     display: 'inline'
// }