import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import Navbar from './components/layouts/Navbar';
import Footer from './components/layouts/Footer';
import Welcome from './components/basics/Welcome';
import About from './components/basics/About';
import Labs from './components/basics/Labs';


function App() {
  return (
    <Router>
      <div className="App">
        <div style={mainStyle}>
          <Navbar />
          <div style={contentStyle}>
            <Route exact path="/" component={Welcome} />
            <Route exact path="/labs" component={Labs} />
            <Route exact path="/about" component={About} />
          </div>
        </div>
        <div style={paddingStyle}></div>
        <Footer />
      </div>
    </Router>
  );
}

const mainStyle = {
  minHeight: 'calc(100vh - 176px)'
}

const paddingStyle = {
  paddingBottom: '115px'
}

const contentStyle = {
  marginTop: '80px'
}

export default App;


///////////////////////////////////
// TO BE DELETED JUST PRACTICE
// class App extends React.Component {
//   constructor(props) {
//     super(props);
//     this.state = { apiResponse: "" };
//   }

//   callAPI() {
//       fetch("http://localhost:9000/testAPI")
//           .then(res => res.text())
//           .then(res => this.setState({ apiResponse: res }));
//   }

//   componentWillMount() {
//       this.callAPI();
//   }
//   render() {
//     return (
//       <div className="App">
//         <p className="App-intro">;{this.state.apiResponse}</p>
//       </div>
//     );
//   }
// }

// export default App;
//////////////////////////////////


